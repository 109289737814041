import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    rootWrapper: {
        position: "relative",
        height: "100%",
        width: "100%",
        overflow: "hidden"
    },
    gridContainer: {
        position: "absolute",
        top: 0,
        left: 0,
        right: "-17px", // hide scrollbar
        bottom: "-17px", // hide scrollbar
        display: "grid",
        overflow: "auto",
        backgroundColor: "#f8f8f8"
    },
    grid: {
        display: "flex",
        flexWrap: "nowrap",
        position: "relative"
    },
    timesColumn: {
        position: "relative",
        fontSize: "9px",
        backgroundColor: theme.palette.grey[50]
    },
    timesColumnItems: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "end",
        paddingRight: "3px",
        color: "rgba(126, 126, 126, 1)",
        position: "relative",
        fontSize: "12px"
    },
    headerRooms: {
        color: theme.palette.grey[500],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderLeft: `1px solid ${theme.palette.grey[100]}`,
        zIndex: theme.custom.zIndex.canvas + 30,
        fontWeight: "bold",
        backgroundColor: theme.palette.grey[50],
        height: theme.custom.dayViewCanvas.headerHeight,
        position: "sticky",
        top: 0
    },
    pageButton: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "1.5rem",
        backgroundColor: "#fff",
        color: theme.palette.primary.main,
        height: "2rem",
        fontSize: "1.3rem",
        fontWeight: "500"
    },
    pageRight: {
        right: "-1.5rem",
        borderRadius: "0 0.6rem 0.6rem 0"
    },
    pageLeft: {
        left: "-1.5rem",
        borderRadius: "0.6rem 0 0 0.6rem"
    },
    pageHover: {
        "cursor": "pointer",
        "&:hover": {
            filter: "brightness(85%)"
        }
    },
    dayviewRoomColumn: {
        zIndex: theme.custom.zIndex.canvas + 20,
        flex: 1,
        position: "relative",
        overflow: "hidden"
    },
    horizontalLines: {
        position: "absolute",
        backgroundColor: theme.palette.grey[100],
        height: "2px",
        width: "100%",
        zIndex: theme.custom.zIndex.canvas + 20
    },
    hourLabel: {
        position: "absolute",
        fontSize: "14px",
        right: "10px",
        fontWeight: theme.typography.fontWeightMedium
    },
    minuteLabel: {
        position: "absolute",
        fontSize: "10px",
        right: "10px",
        color: theme.palette.grey[500]
    },
    currentTimeLabel: {
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: "600",
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
        width: "3rem",
        height: "1.2rem",
        left: "26px",
        zIndex: theme.custom.zIndex.canvas + 35,
        borderRadius: "3px"
    },
    currentTimeLine: {
        position: "absolute",
        display: "flex",
        height: "0.063rem",
        backgroundColor: theme.palette.primary.main,
        zIndex: theme.custom.zIndex.canvas + 35,
        width: "100%"
    },
    disabled: {
        color: theme.palette.text.disabled
    },
    roomsHeaderLeftPadding: {
        width: theme.custom.dayViewCanvas.timesColumnWidth
    },
    gridColTimes: {
        left: 0,
        position: "sticky",
        background: theme.palette.background.paper,
        borderRight: `1px solid ${theme.palette.grey[100]}`,
        zIndex: theme.custom.zIndex.canvas + 25
    },
    gridItemHeader: {
        height: theme.custom.dayViewCanvas.headerHeight,
        position: "sticky",
        top: 0,
        zIndex: theme.custom.zIndex.canvas + 30,
        width: "100%"
    },
    gridItem: {
        borderBottom: `1px solid ${theme.palette.grey[100]}`
    },
    pastArea: {
        position: "absolute",
        backgroundColor: theme.palette.primary.main,
        zIndex: theme.custom.zIndex.canvas + 18,
        opacity: "0.05",
        width: "100%"
    },
    content: {
        overflowY: "hidden",
        position: "relative"
    },
    cornerCell: {
        color: theme.palette.grey[500],
        position: "sticky",
        top: 0,
        background: "transparent",
        height: theme.custom.dayViewCanvas.headerHeight,
        width: theme.custom.dayViewCanvas.timesColumnWidth
    },
    visibleSlot: {
        position: "absolute",
        zIndex: theme.custom.zIndex.canvas + 20,
        opacity: "50%",
        borderRadius: "5px"
    },
    visibleSlotEmergency: {
        backgroundColor: theme.custom.emergency.main
    },
    tickLong: {
        position: "absolute",
        right: 0,
        width: "7px",
        height: "2px",
        backgroundColor: theme.palette.grey[100]
    },
    tick: {
        position: "absolute",
        right: 0,
        width: "4px",
        height: "2px",
        backgroundColor: theme.palette.grey[100]
    }
}));
