// @ts-check
import {useTheme} from "@mui/material";
import {array, func, number, object, oneOfType, string} from "prop-types";
import React, {useContext} from "react";
import {useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectSelectedDate} from "../../../pages/day_view/day_view_selectors";
import {checkOpOverlap} from "../../../utils/check_op_overlap";
import getDiscipline from "../../../utils/get_discipline";
import getDurations from "../../../utils/get_durations";
import getInterventions from "../../../utils/get_interventions";
import {getParticipantCategories} from "../../../utils/get_participant_category";
import getTimestamps from "../../../utils/get_timestamps";
import {isEmergencyFunc} from "../../../utils/is_emergency";
import {sortOpByPriorityAndStartTime} from "../../../utils/sort_op_by_priority_and_start_time";
import {selectFeSettings} from "../../fe_settings/fe_settings_selectors";
import {OpBoxVerticalWrapper} from "../../op_box_vertical/op_box_vertical_wrapper";
import getPractitionerIdsFromScheduleOpByCategory from "../../private_data/utils/get_practitioner_ids_from_schedule_ops_by_category";

const CPB = "CPB";
/**
 * @typedef {Object} OpBoxesFilters
 * @property {Array<String>} [roomFilter]
 * @property {Array<String>} [disciplinesFilter]
 * @property {Array<String>} [occupiedOpRooms]
 * @property {Object<string, string[]>} [disciplineRoomsMapping]
 */

/**
 * Renders the vertical boxes in the day_view_canvas_component
 * @param {object} props
 * @param {OpBoxesFilters} props.filters
 * @param {Function} props.handleOpenDetails
 * @param {Array<PlanBox>} props.opDataState
 * @param {String} props.selectedOp appointmentId of selected
 * @return {React.ReactElement} The positioned vertical boxes
 */
export const OpBoxes = ({filters: {disciplinesFilter}, opDataState, selectedOp, handleOpenDetails}) => {
    const {fromISO, format, diffDT, startOf} = useContext(DateContext);
    const {emergencyThreshold, additionalOpInfo, participantCategoriesForHealthcareService} = useSelector(selectFeSettings);
    const selectedDate = useSelector(selectSelectedDate);

    const bars = [];
    // @ts-ignore
    const {dayViewCanvas} = useTheme().custom;
    const rowHeight = parseInt(dayViewCanvas.rowHeight, 10);

    const isDisciplinesFiltering = disciplinesFilter.length > 0;

    // sort by start time to overlap the ops started later on the ops started earlier
    const sortedOpData = [...opDataState].sort(sortOpByPriorityAndStartTime(emergencyThreshold));

    for (const opData of sortedOpData) {
        const discipline = getDiscipline(opData);
        const isOpDisciplineSelected = disciplinesFilter.includes(discipline);

        // Filter out NOT selected Disciplines
        if (isDisciplinesFiltering && !isOpDisciplineSelected) {
            continue;
        }
        const {id: opId, _status: opStatus, _patient: patient, _surgeonPresenting: surgeonPresenting, _isLocked: isLocked} = opData;
        const timestamps = getTimestamps(opData);

        const intervention = getInterventions(opData) || "";
        const team = getPractitionerIdsFromScheduleOpByCategory(
            opData._team,
            getParticipantCategories({participantCategoriesForHealthcareService, hcServiceId: discipline})
        );
        const durations = getDurations(opData);

        const medicalClearance = opData._medicalApproval?.find((approval) => approval.type === "next_medClearance");

        let hexDiscipline = "";
        if (opData._styles.hexDiscipline) {
            hexDiscipline = opData._styles.hexDiscipline;
        }

        const emergency = isEmergencyFunc(opData._priority, emergencyThreshold);

        let isSelectedOp = false;
        if (selectedOp === opId) {
            isSelectedOp = true;
        }

        // op unix times
        const opEnterDT = fromISO(timestamps.roomLockStart);
        const opStartFromTheStartOfTheDayInSeconds = diffDT(opEnterDT, startOf(selectedDate, "day"), "seconds");

        const opExitDT = fromISO(timestamps.roomLockEnd);
        const opEndFromTheStartOfTheDayInSeconds = diffDT(opExitDT, startOf(selectedDate, "day"), "seconds");

        // calculate box top separation from the top
        const rowHeightPerSecond = rowHeight / (60 * 60);
        const topSeparationToBoxTop = Math.floor(opStartFromTheStartOfTheDayInSeconds * rowHeightPerSecond);

        // calculate box buttom separation from the top
        const topSeparationToBoxBottom = Math.floor(opEndFromTheStartOfTheDayInSeconds * rowHeightPerSecond);
        // calculate op box height
        const height = topSeparationToBoxBottom - topSeparationToBoxTop;

        // calculate op status duration in percent
        const total = durations.duraRoomLockPost && durations.duraRoomLockPost.refEnd;
        const pre = durations.duraRoomLockPre && durations.duraRoomLockPre.refEnd - durations.duraRoomLockPre.refStart;
        const op = durations.duraRoomLockOp && durations.duraRoomLockOp.refEnd - durations.duraRoomLockOp.refStart;
        const post = durations.duraRoomLockPost && durations.duraRoomLockPost.refEnd - durations.duraRoomLockPost.refStart;

        // If customer plan, there is no duraRoomLockPost (only duraRoomLock is available), do not show durations for pre, op, post
        const calcPercent = (opStep, total) => (total ? (opStep * 100) / total : 0);

        const durationPrePercent = calcPercent(pre, total);
        const durationOpPercent = calcPercent(op, total);
        const durationPostPercent = calcPercent(post, total);

        // start time
        const preStartDT = fromISO(durations.duraRoomLockPre?.dtStart);
        const startOpTime = format(preStartDT, DATE_FORMATS.TIME);

        const isMedicalClearanceDone = medicalClearance && Boolean(medicalClearance.practitionerId); // true if medicalClearance was found and practitionerId was set

        // @description CPB time is only shown for DHM
        const duraCpb = additionalOpInfo && additionalOpInfo.includes(CPB) && durations.duraCpb;

        /** @type {OpBoxVerticalContent} */
        const content = {
            team,
            patient,
            intervention,
            hexDiscipline,
            durationPrePercent,
            durationOpPercent,
            durationPostPercent,
            startOpTime,
            duraCpb,
            isMedicalClearanceDone,
            surgeonPresenting,
            isLocked
        };
        const {hasOverlap, adjustIndex} = checkOpOverlap(opData, opDataState);
        bars.push(
            <OpBoxVerticalWrapper
                adjustIndex={adjustIndex}
                content={content}
                emergency={emergency}
                handleOpenDetails={handleOpenDetails}
                hasOverlap={hasOverlap}
                height={height}
                isSelectedOp={isSelectedOp}
                key={opId}
                opId={opId}
                status={opStatus}
                topSeparation={topSeparationToBoxTop}
            />
        );
    }
    return <>{bars}</>;
};

OpBoxes.propTypes = {
    filters: object,
    selectedOp: oneOfType([number, string]),
    opDataState: array,
    handleOpenDetails: func
};
