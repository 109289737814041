// @ts-check
import {useTheme} from "@mui/material";
import React from "react";

import useStyles from "../day_view_canvas.styles";

/**
 * Renders the horizontal lines in the day_view_canvas component
 *
 * @return {React.ReactElement} The horizontal lines representing the hours of the grid
 */
export const HorizontalLines = () => {
    const {classes} = useStyles();
    // @ts-ignore
    const {dayViewCanvas} = useTheme().custom;
    const rowHeight = parseInt(dayViewCanvas.rowHeight, 10);

    const hoursToRender = 24;
    const hours = Array.from({length: hoursToRender + 1}, (_, i) => i);

    return (
        <>
            {hours.map((hour) => {
                const top = `${rowHeight * hour}px`;
                return <div className={classes.horizontalLines} key={hour} style={{top}} />;
            })}
        </>
    );
};
