// @ts-check
import {Undo} from "@mui/icons-material";
import {Button, IconButton, LinearProgress} from "@mui/material";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../config/config.json";
import {maxDurationBlocked, WS_ACTIONS, WS_EVENTS} from "../../../config/event_config";
import {DateContext} from "../../contexts/dates";
import {SocketContext} from "../../contexts/websocket/websocket";
import {
    selectManualChanges,
    selectSessionEditedBy,
    selectSessionId,
    selectSolverEvent
} from "../../pages/op_management/op_management_selectors";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../redux/app_selectors";
import {selectBlockedUntil, selectEventId, selectEventKey} from "../../redux/events/event_selectors";
import ManualChanges from "../manage/components/manual_changes";
import NewPlanDiscardedIcon from "../shared/icons/new_plan_discarded_icon";
import NewPlanIcon from "../shared/icons/new_plan_icon";
import NewPlanSavedIcon from "../shared/icons/new_plan_saved_icon";
import useStyles from "./blocked_screen.styles";

/**
 * Block screen page
 * @param {Object} props
 * @param {BlockScreenStype} props.type
 * @return {React.ReactElement}
 */
const BlockedScreen = ({type}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const socket = useContext(SocketContext);
    const {fromISO, now, diffDT} = useContext(DateContext);

    // Redux
    // from websocket event
    const eventBlockedUntil = useSelector(selectBlockedUntil);
    const eventId = useSelector(selectEventId);
    const eventKey = useSelector(selectEventKey);

    // from customer
    const userEmail = useSelector(selectCurrentUserEmail);
    const organizationId = useSelector(selectCurrentOrganizationId);

    // from status call
    const sessionId = useSelector(selectSessionId);
    const sessionEditedBy = useSelector(selectSessionEditedBy);
    const stateSolverEvent = useSelector(selectSolverEvent);
    const manualChanges = useSelector(selectManualChanges);

    // State
    /** @type [Number, Function] */
    const [remainingTimeSec, setRemainingTimeSec] = useState();
    /**
     * @type {Array<ManualChange>}
     */
    const sortedManualChanges = useMemo(
        () => (manualChanges ? [...manualChanges].sort((a, b) => diffDT(fromISO(b.timest), fromISO(a.timest), "seconds")) : []),
        [manualChanges]
    );

    const handleMouseMove = (event) => {
        event.stopPropagation();
        if (type === "newPlanUnsaved" && eventKey !== "AutomaticUpdateSchedulerPlanCritical") {
            socket.emit(
                WS_EVENTS.UserSolveEvent,
                JSON.stringify({
                    action: WS_ACTIONS.Snooze,
                    organizationId,
                    userEmail,
                    eventId: eventId || stateSolverEvent?.eventId
                })
            );
        }
    };

    const debouncedMouseMoveHandler = useCallback(debounce(handleMouseMove, 500), [type]);

    useEffect(() => {
        return () => {
            debouncedMouseMoveHandler.cancel();
        };
    }, []);

    useEffect(() => {
        if (type === "newPlanUnsaved" && sessionEditedBy === userEmail) {
            window.addEventListener("mousemove", debouncedMouseMoveHandler);
        }
        // Specify how to clean up after this effect:
        return () => {
            if (type === "newPlanUnsaved" && sessionEditedBy === userEmail) {
                window.removeEventListener("mousemove", debouncedMouseMoveHandler);
            }
        };
    }, [type, socket, sessionEditedBy, userEmail]);

    // calculate remaining time if blockUntil has been changed
    useEffect(() => {
        let blockInterval;
        if (type === "newPlanUnsaved") {
            const eventBlockedUntilDT = fromISO(eventBlockedUntil);
            const stateBlockedUntilDT = fromISO(stateSolverEvent?.blockedUntil);

            // Initial set of remainingTime
            const until = eventBlockedUntilDT > stateBlockedUntilDT ? eventBlockedUntilDT : stateBlockedUntilDT || now();
            calculateRemainingTime(until);
            blockInterval = setInterval(() => calculateRemainingTime(until), config.INTERVAL_BLOCK_SCREEN_SEC * 1000);
        }
        return () => {
            clearInterval(blockInterval);
        };
    }, [type, eventBlockedUntil, stateSolverEvent]);

    /**
     * calculate remaining time
     * @param {DateTimeType} until
     */
    const calculateRemainingTime = (until) => {
        const time = diffDT(until, now(), "seconds");
        setRemainingTimeSec(time);
    };

    const handleUndo = () => {
        debouncedMouseMoveHandler.cancel();
        const lastChange = sortedManualChanges[0];
        if (lastChange) {
            // Remove mousemove eventlister
            window.removeEventListener("mousemove", debouncedMouseMoveHandler);
            // Send userSolveEvent
            socket.emit(
                WS_EVENTS.UserSolveEvent,
                JSON.stringify({
                    action: WS_ACTIONS.UserUndoManualChange,
                    organizationId,
                    sessionId,
                    id: lastChange.id,
                    userEmail,
                    eventId: eventId || stateSolverEvent?.eventId
                })
            );
        }
    };

    const handleDiscard = () => {
        // Remove mousemove eventlister
        window.removeEventListener("mousemove", debouncedMouseMoveHandler);
        debouncedMouseMoveHandler.cancel();
        // Send userSolveEvent
        socket.emit(
            WS_EVENTS.UserSolveEvent,
            JSON.stringify({
                action: WS_ACTIONS.UserDiscardChanges,
                organizationId,
                sessionId,
                userEmail,
                eventId: eventId || stateSolverEvent?.eventId
            })
        );
    };

    const handleComplete = () => {
        // Remove mousemove eventlister
        window.removeEventListener("mousemove", debouncedMouseMoveHandler);
        debouncedMouseMoveHandler.cancel();
        // Send userSolveEvent
        socket.emit(
            WS_EVENTS.UserSolveEvent,
            JSON.stringify({
                action: WS_ACTIONS.UserCompleteChanges,
                organizationId,
                sessionId,
                userEmail,
                eventId: eventId || stateSolverEvent?.eventId
            })
        );
    };

    // Set icon and icon className
    const iconMapping = {
        newPlanAutomatic: {iconName: NewPlanIcon, className: classes.iconNewPlan},
        newPlanManual: {iconName: NewPlanIcon, className: classes.iconNewPlan},
        newPlanUnsaved: {iconName: NewPlanIcon, className: classes.iconNewPlan},
        newPlanSaved: {iconName: NewPlanSavedIcon, className: classes.iconNewPlanSaved}, // @TODO #14782: Remove unused icons
        newPlanDiscarded: {iconName: NewPlanDiscardedIcon, className: classes.iconNewPlanDiscarded}, // @TODO #14782: Remove unused icons
        minorAdjust: {iconName: NewPlanIcon, className: classes.iconNewPlan},
        failure: {iconName: NewPlanIcon, className: classes.iconNewPlan},
        Default: {iconName: NewPlanIcon, className: classes.iconNewPlan}
    };
    const IconName = type ? iconMapping[type].iconName : iconMapping.Default.iconName;
    const className = type ? iconMapping[type].className : iconMapping.Default.className;
    return (
        <div
            className={cx({
                [classes.wrapper]: type === "newPlanUnsaved"
            })}
        >
            <div
                className={cx(classes.root, {
                    [classes.rootNewPlanUnsaved]: type === "newPlanUnsaved"
                })}
                data-testid={`blocked-screen-${type}`}
            >
                <IconName className={className} />
                <div
                    className={cx(classes.header, {
                        [classes.headerNewPlanUnsaved]: type === "newPlanUnsaved"
                    })}
                    data-testid={`blocked-screen-header-${type || "newPlan"}`}
                >
                    {type &&
                        (userEmail !== sessionEditedBy && type === "newPlanUnsaved" ? (
                            <Trans components={{break: <br />}} i18nKey={`BlockedScreen.${type}-forOtherUsers`} />
                        ) : (
                            <Trans components={{break: <br />}} i18nKey={`BlockedScreen.${type}`} />
                        ))}
                </div>
                <div
                    className={cx(classes.text, {
                        [classes.textNewPlanUnsaved]: type === "newPlanUnsaved"
                    })}
                    data-testid={`blocked-screen-text-${type || "newPlan"}`}
                >
                    {type &&
                        (userEmail !== sessionEditedBy && type === "newPlanUnsaved"
                            ? t(`BlockedScreen.text-${type}-forOtherUsers`)
                            : t(`BlockedScreen.text-${type}`))}
                </div>
                {type === "failure" && (
                    <div className={classes.text} data-testid={"blocked-screen-text-2"}>
                        {type && t(`BlockedScreen.text-${type}-2`)}
                    </div>
                )}
                {type === "newPlanUnsaved" && (
                    <div className={classes.counter} data-testid={`blocked-screen-counter-${type}`}>
                        <span>{t("BlockedScreen.remainingMin", {num: remainingTimeSec ? Math.floor(remainingTimeSec) : 0})}</span>
                    </div>
                )}
            </div>
            {type === "newPlanUnsaved" && (
                <div className={classes.manualChanges}>
                    <div className={classes.unsavedChanges}>
                        <span>{t("BlockedScreen.numUnsavedChanges", {count: manualChanges?.length})}</span>
                        <IconButton className={classes.undo} disabled={userEmail !== sessionEditedBy} size="large" onClick={handleUndo}>
                            <Undo className={classes.icon} />
                        </IconButton>
                    </div>
                    <div className={classes.manualChangesInner}>
                        <ManualChanges isBlockScreen manualChanges={manualChanges} />
                    </div>
                </div>
            )}
            {type === "newPlanUnsaved" && (
                <LinearProgress
                    classes={{root: classes.linearProgressRoot}}
                    value={((maxDurationBlocked - remainingTimeSec) / maxDurationBlocked) * 100}
                    variant="determinate"
                />
            )}
            {type === "newPlanUnsaved" && manualChanges?.length && userEmail === sessionEditedBy && (
                <>
                    <Button
                        className={classes.discardButton}
                        color="primary"
                        data-testid="blocked-screen-discard-button"
                        key="discardManualChanges"
                        variant="contained"
                        onClick={handleDiscard}
                    >
                        {t("BlockedScreen.reset")}
                    </Button>
                    <Button
                        className={classes.saveButton}
                        color="primary"
                        data-testid="blocked-screen-save-button"
                        key="completeManualChanges"
                        variant="contained"
                        onClick={handleComplete}
                    >
                        {t("BlockedScreen.save")}
                    </Button>
                </>
            )}
        </div>
    );
};

BlockedScreen.propTypes = {
    type: PropTypes.oneOf(["newPlanAutomatic", "newPlanUnsaved", "newPlanManual", "minorAdjust", "failure"])
};
export default BlockedScreen;
