// @ts-check
import {useTheme} from "@mui/material";
import {array, func, number, string} from "prop-types";
import React from "react";
import {useSelector} from "react-redux";

import {selectFilters} from "../../../pages/day_view/day_view_selectors";
import {selectData} from "../../../pages/timeslots/timeslots_selectors";
import {DAYVIEW_CANVAS_START_HOUR} from "../day_view_canvas";
import useStyles from "../day_view_canvas.styles";
import {CurrentTimeLine} from "./current_time_line";
import DayViewDisciplineSlots from "./day_view_discipline_slots";
import {HorizontalLines} from "./horizontal_lines";
import {OpBoxes} from "./op_boxes";

/**
 * Renders the day view room column in the day_view_canvas component
 *
 * @param {object} props
 * @param {Array<{id: string, name: string}>} props.roomInfos
 * @param {number} props.canvasStartHour
 * @param {number} props.currentTimeHeight
 * @param {Array<PlanBox>} props.ops
 * @param {Function} props.onOpenDetails
 * @param {string} props.selectedOp
 * @return {React.ReactElement} The vertical lines representing each op room column
 */
export const DayViewRoomColumn = ({roomInfos, canvasStartHour, currentTimeHeight, ops, onOpenDetails, selectedOp}) => {
    const {classes} = useStyles();
    // @ts-ignore
    const {dayViewCanvas} = useTheme().custom;

    const filters = useSelector(selectFilters);
    const timeslots = useSelector(selectData);

    const maxWidth = dayViewCanvas.maxColumnWidth;
    const minWidth = dayViewCanvas.minColumnWidth;
    const rowHeight = parseInt(dayViewCanvas.rowHeight, 10);

    const gridTotalHeight = rowHeight * (24 - canvasStartHour);

    return (
        <>
            {roomInfos.map((room, i) => {
                const borderRight = `${i !== roomInfos.length - 1 ? "" : "1px solid #eeeeee"}`; // add right border to the last column
                const borderLeft = `${i === 0 ? "" : "1px solid #eeeeee"}`; // skip the first border so it doesn't overlap
                const backgroundColor = i % 2 === 0 ? "#fcfcfc" : "#ffffff";

                return (
                    <div
                        key={room.id}
                        style={{
                            minWidth,
                            maxWidth,
                            flex: 1
                        }}
                    >
                        <div
                            className={classes.headerRooms}
                            key={`header-${room.id}`}
                            role="columnheader"
                            style={{minWidth, maxWidth}}
                            title={room.name}
                        >
                            {room.name}
                        </div>
                        <div
                            className={classes.dayviewRoomColumn}
                            key={`column-${room.id}`}
                            style={{
                                height: `${gridTotalHeight}px`,
                                minWidth,
                                maxWidth,
                                backgroundColor,
                                borderLeft,
                                borderRight
                            }}
                        >
                            <HorizontalLines />
                            <OpBoxes
                                filters={filters}
                                handleOpenDetails={onOpenDetails}
                                opDataState={ops.filter((op) => op._location.reference === room.id)}
                                selectedOp={selectedOp}
                            />
                            <DayViewDisciplineSlots
                                canvasStartHour={DAYVIEW_CANVAS_START_HOUR}
                                disciplineSlots={timeslots.filter((slot) => slot.locations.includes(room.id))}
                            />
                            {!!currentTimeHeight && (
                                <div
                                    className={classes.pastArea}
                                    style={{
                                        height: `${currentTimeHeight}px`
                                    }}
                                />
                            )}
                            {!!currentTimeHeight && <CurrentTimeLine currentTimeHeight={currentTimeHeight} />}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

DayViewRoomColumn.propTypes = {
    roomInfos: array.isRequired,
    canvasStartHour: number.isRequired,
    currentTimeHeight: number.isRequired,
    ops: array.isRequired,
    onOpenDetails: func.isRequired,
    selectedOp: string
};
