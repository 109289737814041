// @ts-check
import {useTheme} from "@mui/material";
import {array, bool, func, string} from "prop-types";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {saveFiltersAction, saveOpRooms} from "../../pages/day_view/day_view_actions";
import {selectFilters, selectSelectedDate} from "../../pages/day_view/day_view_selectors";
import checkRoomsFilter from "../../utils/check_rooms_filter";
import getDiscipline from "../../utils/get_discipline";
import getRoom from "../../utils/get_room";
import getTimestamps from "../../utils/get_timestamps";
import sortLocation from "../../utils/sort_location";
import {selectThemeLocationInfos} from "../fe_settings/fe_settings_selectors";
import {selectRoomInfos} from "../rooms/rooms_selectors";
import {DayViewRoomColumn} from "./components/day_view_room_column";
import {HoursColumn} from "./components/hours_column";
import useStyles from "./day_view_canvas.styles";
import {calculateCurrentTimeHeight} from "./day_view_utils";

export const DAYVIEW_CANVAS_START_HOUR = 0;
/**
 * Render canvas in the Day View Page
 * @param {Object} props
 * @param {Function} props.handleOpenDetails
 * @param {PlanBox[]} props.opData
 * @param {string[]} props.opRooms
 * @param {Boolean} props.isCustomerPlan
 * @param {String} props.selectedOp appointmentId
 * @param {Boolean} props.isFullscreen
 * @return {React.ReactElement}
 */
export const DayViewCanvas = ({handleOpenDetails, opData, opRooms, isCustomerPlan, selectedOp, isFullscreen}) => {
    const dispatch = useDispatch();
    const {classes} = useStyles();
    const canvasRef = useRef(null);

    // @ts-ignore
    const {dayViewCanvas} = useTheme().custom;
    const {areSame, format, now, startOf, getDT, setDT, minusDT, diffDT, fromISO} = useContext(DateContext);

    // Redux
    const selectedDate = useSelector(selectSelectedDate);
    const filters = useSelector(selectFilters);
    const roomInfos = useSelector(selectRoomInfos);
    const locationInfos = useSelector(selectThemeLocationInfos);

    // State
    const [opDataState, setOpDataState] = useState(opData);
    const [currentDateTime, setCurrentDateTime] = useState(now());
    const [loading, setLoading] = useState(true);
    const [hasToRecalculateCanvas, setHasToRecalculateCanvas] = useState(false);
    const [initialScrollToHour, setInitialScrollToHour] = useState(0);

    // Constants
    const isCurrentDay = areSame(selectedDate, now(), "day");
    const rowHeight = parseInt(dayViewCanvas.rowHeight, 10);
    const headerHeight = parseInt(dayViewCanvas.headerHeight, 10);
    const timesColumnWidth = parseInt(dayViewCanvas.timesColumnWidth, 10);
    const currentTimeHeight = calculateCurrentTimeHeight({
        currentHour: getDT(currentDateTime, "hour"),
        currentMinute: getDT(currentDateTime, "minute"),
        rowHeight,
        canvasStartHour: DAYVIEW_CANVAS_START_HOUR
    });

    useEffect(() => {
        const loadOperations = async () => await loadOps();
        loadOperations();
    }, [opData]);

    useEffect(() => {
        setHasToRecalculateCanvas(true);
    }, [opRooms, selectedDate, isCustomerPlan]);

    // Initial scroll for non fullscreen mode
    useEffect(() => {
        if (canvasRef?.current && !isFullscreen) {
            // Get the max. scrollable pixel
            const maxScrollableHeight = canvasRef.current.scrollHeight - canvasRef.current.clientHeight;
            const scrollToPixel = rowHeight * initialScrollToHour;
            canvasRef.current.scrollTop = maxScrollableHeight > scrollToPixel ? scrollToPixel : maxScrollableHeight;
        }
    }, [initialScrollToHour, canvasRef, isFullscreen]);

    // auto scroll for fullscreen mode
    useEffect(() => {
        if (canvasRef?.current && currentDateTime && isCurrentDay && isFullscreen) {
            // Calculate scroll target time: the current time - 3 hours
            const scrollTargetTime = minusDT(currentDateTime, "hour", 3);

            // Calculate pixel from the top to scroll target time
            const startTime = setDT(startOf(now(), "day"), {hour: DAYVIEW_CANVAS_START_HOUR});
            const diffMinutes = diffDT(scrollTargetTime, startTime, "minutes");
            const diffPixel = (rowHeight / 60) * diffMinutes;

            // Get the max. scrollable pixel
            const maxScrollableHeight = canvasRef.current.scrollHeight - canvasRef.current.clientHeight;

            // Scroll to the scroll target time or max scrollable position
            canvasRef.current.scrollTop = diffPixel > maxScrollableHeight ? maxScrollableHeight : diffPixel;
        }
    }, [currentDateTime, canvasRef, isCurrentDay, isFullscreen]);

    const currentTimeString = useMemo(() => {
        return format(currentDateTime, DATE_FORMATS.TIME);
    }, [currentDateTime]);

    /**
     * Iterates over the op data and find some basic infos
     * @param {Array} data
     * @param {Object} filters
     * @param {Array} roomInfos
     * @return {DateTimeType}
     */
    const analyzeOpData = (data, filters, roomInfos) => {
        let earliestOpStart = null;

        const grouping = {};
        const disciplineRoomsMapping = {}; // for disciplines vs rooms mapping
        for (const opData of data) {
            const {roomLockStart} = getTimestamps(opData);
            const room = getRoom(opData);
            const discipline = getDiscipline(opData);

            if (room !== null) {
                grouping[room] = 0;

                const enterOp = fromISO(roomLockStart);
                if (areSame(selectedDate, enterOp, "day") && (earliestOpStart === null || enterOp < earliestOpStart)) {
                    earliestOpStart = enterOp;
                }
            }
            // Grouping by discipline
            if (!disciplineRoomsMapping[discipline]) {
                disciplineRoomsMapping[discipline] = [room];
            } else {
                disciplineRoomsMapping[discipline].push(room);
            }
        }
        Object.keys(disciplineRoomsMapping).forEach((discipline) => {
            disciplineRoomsMapping[discipline] = [...new Set(disciplineRoomsMapping[discipline])];
            disciplineRoomsMapping[discipline].sort();
        });

        const occupiedOpRooms = Object.keys(grouping).sort();

        const filtersNew = {...filters};
        filtersNew.occupiedOpRooms = occupiedOpRooms;
        filtersNew.disciplineRoomsMapping = disciplineRoomsMapping;
        dispatch(saveFiltersAction(filtersNew));

        // check rooms to be shown according to roomsFilter and disciplinesFilter and save in opRooms
        const newRooms = checkRoomsFilter(
            filtersNew.roomsFilter,
            filtersNew.disciplinesFilter,
            filtersNew.occupiedOpRooms,
            filtersNew.disciplineRoomsMapping,
            roomInfos
        );

        // Save op rooms to be displayed according to filters
        dispatch(saveOpRooms([...newRooms].sort(sortLocation(locationInfos))));
        const newRoomsSorted = [...newRooms];
        newRoomsSorted.sort(sortLocation(locationInfos));

        return earliestOpStart || startOf(selectedDate, "hour");
    };

    /**
     * Load the Ops and save its data in redux store
     */
    const loadOps = async () => {
        const earliestOpStart = analyzeOpData(opData, filters, roomInfos);

        // init scrollbar
        if (hasToRecalculateCanvas) {
            setHasToRecalculateCanvas(false);
        }

        // Set initial scrollTo hour
        if (earliestOpStart !== undefined) {
            const earliestOpHour = getDT(earliestOpStart, "hour");
            const initialScrollToHour = earliestOpHour - 1;
            setInitialScrollToHour(initialScrollToHour);
        }

        setCurrentDateTime(now());
        setOpDataState(opData);
        setLoading(false);
    };

    if (loading) {
        return <div></div>;
    }

    return (
        <div className={classes.rootWrapper} data-testid="dayViewCanvas">
            <div className={classes.gridContainer} ref={canvasRef}>
                <div className={classes.grid}>
                    <div
                        className={classes.gridColTimes}
                        style={{height: `${rowHeight * 24 + headerHeight}px`, width: `${timesColumnWidth}px`}}
                    >
                        <HoursColumn
                            canvasStartHour={DAYVIEW_CANVAS_START_HOUR}
                            currentTimeHeight={currentTimeHeight}
                            currentTimeString={currentTimeString}
                            isCurrentDay={isCurrentDay}
                        />
                    </div>
                    <DayViewRoomColumn
                        canvasStartHour={DAYVIEW_CANVAS_START_HOUR}
                        currentTimeHeight={isCurrentDay ? currentTimeHeight : 0}
                        ops={opDataState}
                        roomInfos={roomInfos.filter((el) => opRooms.includes(el.id))}
                        selectedOp={selectedOp}
                        onOpenDetails={handleOpenDetails}
                    />
                </div>
            </div>
        </div>
        // </div>
    );
};

DayViewCanvas.propTypes = {
    handleOpenDetails: func,
    opData: array,
    opRooms: array,
    isCustomerPlan: bool,
    selectedOp: string,
    isFullscreen: bool
};
