import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    wrapper: {
        display: "flex",
        position: "relative"
    },
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    rootNewPlanUnsaved: {
        background: "#f7f7f7",
        height: "650px",
        width: "420px",
        padding: "0 2rem",
        textAlign: "center"
    },
    manualChanges: {
        height: "650px",
        background: theme.palette.common.white,
        color: theme.palette.text.primary
    },
    manualChangesInner: {
        overflow: "auto",
        height: "570px"
    },
    unsavedChanges: {
        padding: "30px 40px",
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        fontWeight: theme.typography.fontWeightBold,
        display: "flex",
        justifyContent: "space-between",
        fontSize: "1rem"
    },
    header: {
        color: theme.palette.common.white,
        fontSize: "1.875rem",
        marginBottom: theme.spacing("m")
    },
    headerNewPlanUnsaved: {
        color: theme.palette.text.primary,
        fontSize: "16px",
        fontWeight: theme.typography.fontWeightMedium
    },
    text: {
        color: theme.palette.common.white,
        fontSize: "0.875rem",
        textTransform: "uppercase"
    },
    textNewPlanUnsaved: {
        color: theme.palette.text.primary,
        textTransform: "unset"
    },
    iconNewPlanDiscarded: {
        width: "262px",
        height: "170.667px",
        marginBottom: "2.5rem"
    },
    iconNewPlanSaved: {
        width: "262px",
        height: "180px",
        marginBottom: "2.5rem"
    },
    iconNewPlan: {
        width: "262px",
        height: "180px",
        marginBottom: "2.5rem"
    },
    counter: {
        fontSize: "2rem",
        marginTop: "1.5rem",
        marginBottom: theme.spacing("l"),
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightMedium
    },
    discardButton: {
        position: "absolute",
        bottom: "-4rem",
        left: 0
    },
    saveButton: {
        position: "absolute",
        bottom: "-4rem",
        right: 0
    },

    linearProgressRoot: {
        position: "absolute",
        bottom: 0,
        width: "420px" // should be same as rootNewPlanUnsaved
    },

    undo: {
        padding: 0
    },
    icon: {
        width: "1.5rem",
        height: "1.5rem",
        margin: 0
    }
}));
