// @ts-check
/**
 * calculate current time the current time height
 * @param {object} params
 * @param {number} params.currentHour
 * @param {number} params.currentMinute
 * @param {number} params.rowHeight
 * @param {number} params.canvasStartHour - The hour in which the canvas starts
 * @return {number} The distance from the top to the current time
 */
export const calculateCurrentTimeHeight = ({currentHour, currentMinute, rowHeight, canvasStartHour}) => {
    const percentageOfMinutes = (currentMinute / 60) * 100;
    const timeHeight = (currentHour - canvasStartHour) * rowHeight + (percentageOfMinutes * rowHeight) / 100;
    return timeHeight;
};
