// @ts-check
import {useTheme} from "@mui/material";
import {bool, number, string} from "prop-types";
import React from "react";

import useStyles from "../day_view_canvas.styles";

/**
 * Renders the label width the current hour in the day_view_canvas component
 * @param {Object} props
 * @param {Number} props.currentTimeHeight
 * @param {String} props.currentTimeString
 * @return {React.ReactElement} The left label of the current time line
 */
const CurrentTimeLabel = ({currentTimeHeight, currentTimeString}) => {
    const {classes} = useStyles();

    return (
        <div className={classes.currentTimeLabel} style={{top: `${currentTimeHeight - 9}px`}}>
            {currentTimeString}
        </div>
    );
};
CurrentTimeLabel.propTypes = {
    currentTimeHeight: number.isRequired,
    currentTimeString: string.isRequired
};

/**
 * Renders the left column in the day_view_canvas component
 * @param {Object} props
 * @param {String} props.currentTimeString
 * @param {Number} props.currentTimeHeight
 * @param {Boolean} props.isCurrentDay
 * @param {Number} props.canvasStartHour
 * @return {React.ReactElement} The left column with the 24 hours of the day
 */
export const HoursColumn = ({currentTimeString, currentTimeHeight, isCurrentDay, canvasStartHour}) => {
    const {classes, cx} = useStyles();
    // @ts-ignore
    const {dayViewCanvas} = useTheme().custom;
    const {rowHeight, headerHeight} = dayViewCanvas || {};
    const hoursToRender = 24 - canvasStartHour;

    const headerHeightNumber = parseInt(headerHeight, 10);
    const hours = Array.from({length: hoursToRender}, (_, i) => i + canvasStartHour);

    return (
        <>
            <div className={cx(classes.gridColTimes, classes.cornerCell)} />
            {isCurrentDay && (
                <CurrentTimeLabel currentTimeHeight={currentTimeHeight + headerHeightNumber} currentTimeString={currentTimeString} />
            )}
            {hours.map((hour, i) => {
                const height = rowHeight;

                // Set hour, if hour > 24, set hour - 24, if hour < 0 set hour + 24
                const isAfterMidnight = hour > 24;
                const isMinus = hour < 0;
                const hourAdjusted = isAfterMidnight ? hour - 24 : isMinus ? hour + 24 : hour;
                const hourFormatted = `${hourAdjusted === 24 ? "00" : hourAdjusted}:00`;
                const minuteFormatted = `${hourAdjusted === 24 ? "00" : hourAdjusted}:30`;
                const borderBottom = i === hoursToRender - 1 ? "1px solid #eeeeee" : "";
                return (
                    <div className={classes.timesColumnItems} key={hour} style={{height, borderBottom}}>
                        <span className={classes.hourLabel} role="rowheader" style={{top: "-8px"}}>
                            {hourFormatted}
                        </span>
                        <span className={classes.minuteLabel} role="rowheader" style={{top: "calc(50% - 6px)"}}>
                            {minuteFormatted}
                        </span>
                        <div className={classes.tickLong} style={{top: 0}} />
                        <div className={classes.tick} style={{top: "25%"}} />
                        <div className={classes.tickLong} style={{top: "50%"}} />
                        <div className={classes.tick} style={{top: "75%"}} />
                    </div>
                );
            })}
        </>
    );
};

HoursColumn.propTypes = {
    currentTimeHeight: number.isRequired,
    currentTimeString: string.isRequired,
    isCurrentDay: bool.isRequired,
    canvasStartHour: number.isRequired
};
