// @ts-check
import {Box, useTheme} from "@mui/material";
import {arrayOf, number, object} from "prop-types";
import React, {useContext} from "react";
import {useSelector} from "react-redux";

import {DateContext} from "../../../contexts/dates";
import {selectOpRooms, selectVisibleDisciplines} from "../../../pages/day_view/day_view_selectors";
import filterVisibleSlots from "../../../utils/filter_visible_slots";
import {selectDisciplineColors} from "../../fe_settings/fe_settings_selectors";
import useStyles from "../day_view_canvas.styles";

/**
 * @typedef PreCalcDate
 * @type {object}
 * @property {string} start - ISO format
 * @property {string} end - ISO format
 */

/**
 * Renders DayViewDisciplineSlots in dayview
 * @param {object} props
 * @param {DisciplineSlot[]} props.disciplineSlots
 * @param {number} props.canvasStartHour
 * @return {React.ReactElement} The line for the current time line
 */
export const DayViewDisciplineSlots = ({disciplineSlots, canvasStartHour}) => {
    const {getDT, fromISO} = useContext(DateContext);
    const {classes, cx} = useStyles();
    // @ts-ignore
    const {dayViewCanvas} = useTheme().custom;
    const rowHeight = parseInt(dayViewCanvas.rowHeight);

    // redux
    const visibleDisciplines = useSelector(selectVisibleDisciplines);
    const opRooms = useSelector(selectOpRooms);
    const disciplineColors = useSelector(selectDisciplineColors);

    const visibleSlots = filterVisibleSlots(disciplineSlots, visibleDisciplines);

    const slotsJSX = [];
    for (const slot of visibleSlots) {
        const start = slot.preCalcDates[0].start;
        const end = slot.preCalcDates[0].end;
        const healthcareServiceId = slot.healthcareServices[0];
        const locationId = slot.locations?.length && slot.locations?.[0];

        const key = slot.isEmergency ? `emergency-${locationId}-${start}` : `${healthcareServiceId}-${locationId}-${start}`;

        let rowNumber = -1;
        if (opRooms.length && locationId) {
            rowNumber = opRooms.indexOf(locationId);
        }
        if (rowNumber === -1) {
            continue;
        }

        const startDT = fromISO(start);
        const slotEnterHours = getDT(startDT, "hour");
        const slotEnterMinutes = getDT(startDT, "minute");
        const exitDT = fromISO(end);
        const slotExitHours = getDT(exitDT, "hour");
        const slotExitMinutes = getDT(exitDT, "minute");

        // Calculate position from the top
        const percentageOfMinutesEnter = (slotEnterMinutes / 60) * 100;
        const top = (slotEnterHours - canvasStartHour) * rowHeight + (percentageOfMinutesEnter * rowHeight) / 100;

        // Calculate box height
        const percentageOfMinutesExit = (slotExitMinutes / 60) * 100;
        const topSeparationToBoxBottom = (slotExitHours - canvasStartHour) * rowHeight + (percentageOfMinutesExit * rowHeight) / 100;
        const height = topSeparationToBoxBottom - top;

        // discipline color
        const disciplineColor = disciplineColors.find((item) => item.name === healthcareServiceId);

        slotsJSX.push(
            <Box
                aria-label="dayview-discipline-slot"
                className={cx(classes.visibleSlot, {
                    [classes.visibleSlotEmergency]: slot.isEmergency
                })}
                height={height + "px"}
                key={key}
                left={"3%"}
                role="region"
                style={{backgroundColor: !slot.isEmergency && disciplineColor?.hex}}
                top={top + "px"}
                width={"94%"}
            />
        );
    }

    return <>{slotsJSX}</>;
};

DayViewDisciplineSlots.propTypes = {
    disciplineSlots: arrayOf(object), // see typedef
    canvasStartHour: number
};

export default DayViewDisciplineSlots;
