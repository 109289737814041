// @ts-check
import {number} from "prop-types";
import React from "react";

import useStyles from "../day_view_canvas.styles";

/**
 * Renders the current timeline in the day_view_canvas component
 * @param {object} props
 * @param {number} props.currentTimeHeight
 * @return {React.ReactElement} The line for the current time line
 */
export const CurrentTimeLine = ({currentTimeHeight}) => {
    const {classes} = useStyles();
    const top = `${currentTimeHeight}px`;

    return <div className={classes.currentTimeLine} role="separator" style={{top}} />;
};

CurrentTimeLine.propTypes = {
    currentTimeHeight: number.isRequired
};
